<script setup lang="ts">
import { type ImgHTMLAttributes } from 'vue';
import type { DiagnosisResultPersonalityType } from '~theme/assets/images/userThumbnail';
import { thumbnailImages } from '~theme/assets/images/userThumbnail';
import defaultImg from '~theme/assets/images/userThumbnail/img-default.svg';

const props = withDefaults(
  defineProps<{
    personalityType?: Maybe<DiagnosisResultPersonalityType>;
    alt?: ImgHTMLAttributes['alt'];
  }>(),
  {
    personalityType: undefined,
    alt: '',
  }
);

const src = computed((): ImgHTMLAttributes['src'] =>
  props.personalityType !== undefined
    ? thumbnailImages[props.personalityType]
    : defaultImg
);
</script>

<template>
  <img :src="src" :class="$style['thumbnail']" :alt="alt" />
</template>

<style lang="scss" module>
.thumbnail {
  object-fit: contain;
  border-radius: 50%;
  background-color: $color-surface-accent-primary;
}
</style>
