import typeAimg from './img-type-a.png';
import typeBimg from './img-type-b.png';
import typeCimg from './img-type-c.png';
import typeDimg from './img-type-d.png';
import typeEimg from './img-type-e.png';
import typeFimg from './img-type-f.png';
import typeGimg from './img-type-g.png';
import typeHimg from './img-type-h.png';

export enum DiagnosisResultPersonalityType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
}

export const thumbnailImages: Record<DiagnosisResultPersonalityType, string> = {
  [DiagnosisResultPersonalityType.A]: typeAimg,
  [DiagnosisResultPersonalityType.B]: typeBimg,
  [DiagnosisResultPersonalityType.C]: typeCimg,
  [DiagnosisResultPersonalityType.D]: typeDimg,
  [DiagnosisResultPersonalityType.E]: typeEimg,
  [DiagnosisResultPersonalityType.F]: typeFimg,
  [DiagnosisResultPersonalityType.G]: typeGimg,
  [DiagnosisResultPersonalityType.H]: typeHimg,
};
